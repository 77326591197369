import type { OperatingZone } from '@orus.eu/operating-zone'

export const cguLink: Record<OperatingZone, string> = {
  fr: 'https://www.orus.eu/conditions-generales-dutilisation',
  es: 'https://www.orus.eu/es/condiciones-generales-de-uso',
}
export const confidentialityLink: Record<OperatingZone, string> = {
  fr: 'https://www.orus.eu/politique-de-confidentialite',
  es: 'https://www.orus.eu/es/politica-de-privacidad',
}
